import * as React from 'react';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import InputLabel from '@mui/material/InputLabel';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import useWindowSize from '../../hooks/useWindowSize';
import Typography from '@mui/material/Typography';
import { visuallyHidden } from '@mui/utils';
import { styled } from '@mui/material/styles';
import Matches from '../../matches/Matches';
import AppTheme from '../../shared-theme/AppTheme';
import Dashboard from '../../dashboard/Dashboard';
import { db } from '../../lib/firebase';
import { doc, setDoc, Timestamp } from 'firebase/firestore';
import { isMobile } from 'react-device-detect';

const StyledBox = styled('div')(({ theme }) => ({
  alignSelf: 'center',
  width: '100%',
  height: 400,
  marginTop: theme.spacing(8),
  borderRadius: (theme.vars || theme).shape.borderRadius,
  outline: '6px solid',
  outlineColor: 'hsla(220, 25%, 80%, 0.2)',
  border: '1px solid',
  borderColor: (theme.vars || theme).palette.grey[200],
  boxShadow: '0 0 12px 8px hsla(220, 25%, 80%, 0.2)',
  backgroundSize: 'cover',
  [theme.breakpoints.up('sm')]: {
    marginTop: theme.spacing(10),
    height: 700,
  },
  ...theme.applyStyles('dark', {
    boxShadow: '0 0 24px 12px hsla(210, 100%, 25%, 0.2)',
    outlineColor: 'hsla(220, 20%, 42%, 0.1)',
    borderColor: (theme.vars || theme).palette.grey[700],
  }),
}));

const SkewedComponent: React.FC = () => {
  const { width } = useWindowSize();
  const theme = useTheme();
  const isDarkMode = theme.palette.mode === 'dark';

  const dashboardImage = isDarkMode
    ? '/imgs/dashboard_dark.png'
    : '/imgs/dashboard_light.png';

  const matchesImage = isDarkMode
    ? '/imgs/matches_dark.png'
    : '/imgs/matches_light.png';

  React.useEffect(() => {
    // Force a re-render on mount to fix layering issue
    window.dispatchEvent(new Event('resize'));
  }, []);

  const innerDashboard = (width < 900) ? // mobile version
  <StyledBox id="image">
    <img
      src={dashboardImage}
      alt="Dashboard"
      style={{
        pointerEvents: 'none',
        transform: 'scale(0.8)',
        transformOrigin: 'top left',
        height: '125%',
        position: 'relative',
      }}
    />
  </StyledBox>
  : //desktop version
  <StyledBox id="image">
    <AppTheme>
      <Box
        sx={{
          pointerEvents: 'none', // Disable interactions
          transform: 'scale(0.8)', // Scale down to make it appear zoomed out
          transformOrigin: 'top left', // Ensure scaling from top left
          width: '125%', // Adjust width to fit the scaled content
          height: '125%', // Adjust height to fit the scaled content
          position: 'relative',
          overflow: 'hidden',
        }}
      >
        <Dashboard disableCustomTheme />
      </Box>
    </AppTheme>
  </StyledBox>;

  const paddingLeftSize = (width > 900) ? '360px' : '235px';
  const paddingTopSize = (width > 900) ? '200px' : '300px';
  const totalHeight = (width > 900) ? '1250px' : '1000px';

  const innerMatches = (width < 900) ? // mobile version
  <StyledBox id="image">
    <img
      src={matchesImage}
      alt="Matches"
      style={{
        pointerEvents: 'none',
        transform: 'scale(0.8)',
        transformOrigin: 'top left',
        height: '125%',
        position: 'relative',
      }}
    />
  </StyledBox>
  : //desktop version
  <StyledBox id="image">
    <AppTheme>
      <Box
        sx={{
          pointerEvents: 'none', // Disable interactions
          transform: 'scale(0.8)', // Scale down to make it appear zoomed out
          transformOrigin: 'top left', // Ensure scaling from top left
          width: '125%', // Adjust width to fit the scaled content
          height: '125%', // Adjust height to fit the scaled content
          position: 'relative',
          overflow: 'hidden',
        }}
      >
        <Matches disableCustomTheme />
      </Box>
    </AppTheme>
  </StyledBox>;

  return (
    <div
      style={{
        position: 'absolute',
        width: '100%',
        marginTop: '0px',
        paddingTop: paddingTopSize,
        overflow: 'hidden',
        height: totalHeight,
      }}
    >
      <div
        style={{
          position: 'absolute',
          width: '150%',
          marginTop: '0px',
          paddingLeft: paddingLeftSize,
          overflow: 'hidden',
          height: '1200px',
          transform: 'skew(20deg, -3deg) scaleX(1.25)',
          msTransform: 'skew(20deg, -3deg) scaleX(1.25)',
          WebkitTransform: 'skew(20deg, -3deg) scaleX(1.25)',
        }}
      >
        <div
          style={{
            position: 'absolute',
            transformOrigin: 'top left',
            transform: 'translate(-20px, 0px)',
          }}
        >
          {innerDashboard}
        </div>
        <div
          style={{
            transformOrigin: 'top left',
            transform: 'translate(10%, 180px)',
          }}
        >
          {innerMatches}
        </div>
      </div>
    </div>
  );
};

export default function Hero() {
  const { width } = useWindowSize();
  const theme = useTheme();
  const [email, setEmail] = React.useState<string>('');
  const [isSubmitting, setIsSubmitting] = React.useState<boolean>(false);
  const [submitStatus, setSubmitStatus] = React.useState<{ type: 'success' | 'error' | ''; message: string }>({ type: '', message: '' });

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (!email || !email.includes('@')) {
      setSubmitStatus({ type: 'error', message: 'Please enter a valid email address' });
      return;
    }
    setIsSubmitting(true);
    setSubmitStatus({ type: '', message: '' });
    try {
      const emailDoc = doc(db, 'waitlist', email.toLowerCase());
      await setDoc(emailDoc, {
        email: email.toLowerCase(),
        timestamp: Timestamp.now(),
        source: window.location.hostname
      });
      setSubmitStatus({
        type: 'success',
        message: 'Thanks for joining! We\'ll be in touch soon.'
      });
      setEmail('');
    } catch (error) {
      console.error('Error adding email:', error);
      setSubmitStatus({
        type: 'error',
        message: 'Something went wrong. Please try again.'
      });
    } finally {
      setIsSubmitting(false);
    }
  };

  const bottomHeight = (width > 900) ? '900px' : '600px';
  const gradientBottom = (width > 900) ? isMobile ? (
    '-930px'
  ) : (
    '-550px'
  ) : '-160px';
  const gradientBottom2 = (width > 900) ? isMobile ? (
    '-900px'
  ) : (
    '-520px'
  ) : '-130px';
  const backgroundColor = theme.palette.background.default;

  return (
    <Box
      id="hero"
      sx={(theme) => ({
        width: '100%',
        backgroundRepeat: 'no-repeat',
        backgroundImage:
          'radial-gradient(ellipse 80% 50% at 50% -20%, hsl(210, 100%, 90%), transparent)',
        ...theme.applyStyles('dark', {
          backgroundImage:
            'radial-gradient(ellipse 80% 50% at 50% -20%, hsl(210, 100%, 16%), transparent)',
        }),
      })}
    >
      <Container
        sx={{
          zIndex: '5',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          pt: { xs: 14, sm: 20 },
          pb: { xs: 8, sm: 12 },
        }}
      >
        <Stack
          spacing={2}
          useFlexGap
          sx={{ alignItems: 'center', width: { xs: '100%', sm: '70%' }, zIndex: 10}}
        >
          <Typography
            variant="h1"
            sx={{
              display: 'flex',
              flexDirection: { xs: 'column', sm: 'row' },
              alignItems: 'center',
              fontSize: isMobile ? 'clamp(1rem, 10vw, 3.5rem)' : 'clamp(3rem, 10vw, 3.5rem)',
            }}
          >
            Unite&nbsp;innovators,&nbsp;
            <Typography
              component="span"
              variant="h1"
              sx={(theme) => ({
                fontSize: 'inherit',
                color: 'primary.main',
                ...theme.applyStyles('dark', {
                  color: 'primary.light',
                }),
              })}
            >
              ignite&nbsp;ideas.&nbsp;
            </Typography>
          </Typography>
          <Typography
            sx={{
              textAlign: 'center',
              color: 'text.secondary',
              width: { sm: '100%', md: '80%' },
            }}
          >
            Connect with your university's entrepreneurship community to find co-founders, collaborators, or student-led startups at your campus.
          </Typography>
          <form onSubmit={handleSubmit} style={{width: (isMobile ? '100%' : '350px')}}>
            <Stack
              direction={{ xs: 'column', sm: 'row' }}
              spacing={1}
              useFlexGap
              sx={{ pt: 2, width: (isMobile ? '100%' : '350px'), zIndex: '5' }}
            >
              <InputLabel htmlFor="email-hero" sx={visuallyHidden}>
                Email
              </InputLabel>
              <TextField
                id="email-hero"
                hiddenLabel
                size="small"
                variant="outlined"
                aria-label="Enter your email address"
                placeholder="you@university.edu"
                fullWidth
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                disabled={isSubmitting}
                slotProps={{
                  htmlInput: {
                    autoComplete: 'off',
                    'aria-label': 'Enter your email address',
                  },
                }}
              />
              <Button
                type="submit"
                variant="contained"
                color="primary"
                size="small"
                sx={{ minWidth: 'fit-content' }}
                disabled={isSubmitting}
              >
                {isSubmitting ? 'Joining...' : 'Join the waitlist'}
              </Button>
            </Stack>
            {submitStatus.message && (
              <Typography
                variant="caption"
                sx={{
                  mt: 1,
                  color: submitStatus.type === 'error' ? 'error.main' : 'success.main',
                }}
              >
                {submitStatus.message}
              </Typography>
            )}
          </form>
        </Stack>
        <SkewedComponent />
        {/* {!isMobile && width > 900 ? (
        <Box
          sx={{
            position: 'absolute',
            width: '100%',
            height: '500px', // Height for the gradient
            bottom: gradientBottom2, // Adjust this value if needed
            background: `linear-gradient(to bottom, transparent, ${backgroundColor})`,
            pointerEvents: 'none',
          }}
        />
        ) : (<div></div>)}
        {!isMobile && width > 900 ? (
        <Box
          sx={{
            position: 'absolute',
            width: '100%',
            height: '30px', // Height for the solid color
            bottom: gradientBottom, // Adjust to position the solid color below the gradient
            backgroundColor: backgroundColor,
            pointerEvents: 'none',
          }}
        />
        ) : (<div></div>)} */}
        <div
          style={{
            height: bottomHeight,
          }}
        ></div>
      </Container>
    </Box>
  );
}
