import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid2';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import AutoFixHighRoundedIcon from '@mui/icons-material/AutoFixHighRounded';
import ConstructionRoundedIcon from '@mui/icons-material/ConstructionRounded';
import QueryStatsRoundedIcon from '@mui/icons-material/QueryStatsRounded';
import SettingsSuggestRoundedIcon from '@mui/icons-material/SettingsSuggestRounded';
import SupportAgentRoundedIcon from '@mui/icons-material/SupportAgentRounded';
import { PeopleRounded } from '@mui/icons-material';

const items = [
  {
    icon: <SettingsSuggestRoundedIcon />,
    title: 'Seamless Integration',
    description:
      'Effortlessly connect with co-founders and teams, streamlining your startup journey.',
  },
  {
    icon: <ConstructionRoundedIcon />,
    title: 'Robust Community',
    description:
      'Join a thriving network of student innovators, fostering long-term collaboration.',
  },
  {
    icon: <PeopleRounded />,
    title: 'User-Centric Design',
    description:
      'Navigate our intuitive platform with ease, enhancing your entrepreneurial experience.',
  },
  {
    icon: <AutoFixHighRoundedIcon />,
    title: 'Cutting-Edge Matching',
    description:
      'Leverage advanced algorithms to find partners that perfectly complement your skills.',
  },
  {
    icon: <SupportAgentRoundedIcon />,
    title: 'Dedicated Support',
    description:
      'Access our responsive team for guidance and assistance throughout your startup journey.',
  },
  {
    icon: <QueryStatsRoundedIcon />,
    title: 'Data-Driven Insights',
    description:
      'Utilize analytics to make informed decisions and optimize your startup strategy.',
  },
];

export default function Highlights() {
  return (
    <Box
      id="highlights"
      sx={{
        pt: { xs: 1, sm: 3 },
        pb: { xs: 4, sm: 8 },
        color: 'text.primary',
      }}
    >
      <Container
        sx={{
          position: 'relative',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          gap: { xs: 3, sm: 6 },
        }}
      >
        <Box
          sx={{
            width: { sm: '100%', md: '60%' },
            textAlign: { sm: 'left', md: 'center' },
          }}
        >
        </Box>
        <Grid container spacing={2}>
          {items.map((item, index) => (
            <Grid size={{ xs: 12, sm: 6, md: 4 }} key={index}>
              <Stack
                direction="column"
                component={Card}
                spacing={1}
                useFlexGap
                sx={{
                  color: 'inherit',
                  p: 3,
                  height: '100%',
                  borderColor: 'divider',
                  backgroundColor: 'background.paper',
                }}
              >
                <Box sx={{ opacity: '50%' }}>{item.icon}</Box>
                <div>
                  <Typography gutterBottom sx={{ fontWeight: 'medium' }}>
                    {item.title}
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    {item.description}
                  </Typography>
                </div>
              </Stack>
            </Grid>
          ))}
        </Grid>
      </Container>
    </Box>
  );
}
