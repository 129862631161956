import React, { useState } from 'react';
import {
  Box,
  Card,
  CardContent,
  Typography,
  Button,
  TextField,
  Grid,
  Avatar,
  Chip,
  Stack,
} from '@mui/material';
import BookIcon from '@mui/icons-material/Book';

interface MatchProfileProps {
  name: string;
  year: string;
  interests: string[];
  bio: string;
}

interface Profile extends MatchProfileProps {}

const MatchProfile: React.FC<MatchProfileProps> = ({ name, year, interests, bio }) => {
  const [showFullBio, setShowFullBio] = useState(false);

  const toggleBio = () => {
    setShowFullBio(!showFullBio);
  };

  const truncatedBio = bio.length > 130 ? `${bio.substring(0, 127)}...` : bio;

  return (
    <Card
      variant="outlined"
      sx={{ p: 2, boxShadow: 1, '&:hover': { boxShadow: 3 }, minHeight: '175px' }}
    >
      <CardContent>
        <Stack direction="row" spacing={2}>
          <Avatar>{name.split(' ').map(n => n[0]).join('')}</Avatar>
          <Box sx={{ flex: 1, minWidth: 0 }}>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 1 }}>
              <Box sx={{ minWidth: 0 }}>
                <Typography variant="h6" align='left' noWrap>{name}</Typography>
                <Typography
                  variant="caption"
                  color="textSecondary"
                  sx={{ display: 'flex', alignItems: 'center', gap: 0.5, marginTop: '5px' }}
                >
                  <BookIcon style={{ width: '18px', height: '18px' }} />
                  {year} at Vanderbilt University
                </Typography>
              </Box>
              <Button variant="outlined" color="success" size="small">
                Invite
              </Button>
            </Box>
            <Typography variant="body2" color="textSecondary" sx={{ mb: 1 }} align='left'>
              {showFullBio ? bio : truncatedBio}
              {bio.length > 130 && (
                <Typography
                  variant="body2"
                  component="span"
                  sx={{
                    cursor: 'pointer',
                    color: '#3f51b5',
                    '&:hover': {
                      textDecoration: 'underline',
                    },
                    ml: 0.5,
                  }}
                  onClick={toggleBio}
                >
                  {showFullBio ? 'See less' : 'See more'}
                </Typography>
              )}
            </Typography>
            <Stack direction="row" spacing={1} flexWrap="wrap">
              {interests.map((interest, i) => (
                <Chip key={i} label={interest} size="small" sx={{ bgcolor: 'green.50', color: 'green.800' }} />
              ))}
            </Stack>
          </Box>
        </Stack>
      </CardContent>
    </Card>
  );
};

const MainGrid: React.FC = () => {
  const profiles: Profile[] = [
    {
      name: "Raihan Hassam",
      year: "Freshman",
      interests: ["Econ", "Marketing", "Product"],
      bio: "Econ major using AI to develop on-campus solutions to connect student entrepreneurs. Looking for new team members.",
    },
    {
      "name": "Zachary Klinger",
      "year": "Senior",
      "interests": ["Education", "Social Impact", "Entrepreneurship"],
      "bio": "Ingram Scholar at Vanderbilt, co-founder of The Vanderbilt Fusion Project. Passionate about tech-driven educational and social impact, seeking collaborations in HigherEd."
    },
    {
      name: "Soumia Vellanki",
      year: "Senior",
      interests: ["Social Entrepreneurship", "Sustainability", "Financial Inclusion"],
      bio: "Double major in Human and Organizational Development & Environmental Sociology, founder of MakHERs Market. Seeking collaborators passionate about women's empowerment and climate tech.",
    },
    {
      name: "Lane Burgett",
      year: "Freshman",
      interests: ["CS", "Machine Learning", "Electronics"],
      bio: "CS and Math major using machine learning to create Deaflingo: Duolingo for sign language.",
    },
    {
      name: "Charlotte Gibbons",
      year: "Freshman",
      interests: ["Neuroscience", "Pharmacology", "Chemistry"],
      bio: "Neuroscience major studying the effect of experimental drugs on pattern recognition. Looking for research partners.",
    },
    {
      name: "Sarah Chen",
      year: "Junior",
      interests: ["FinTech", "AI/ML", "Product"],
      bio: "CS & Business double major passionate about using AI to revolutionize financial services. Looking for technical co-founders.",
    },
    {
      name: "Marcus Thompson",
      year: "Senior",
      interests: ["EdTech", "Mobile", "UX"],
      bio: "Engineering major with startup experience. Building an adaptive learning platform and seeking co-founders.",
    },
    {
      name: "Priya Patel",
      year: "Sophomore",
      interests: ["Healthcare", "Data", "Impact"],
      bio: "Pre-med & CS student working on healthcare accessibility platform. Seeking technical and business co-founders.",
    }
  ];

  return (
    <Box sx={{ bgcolor: 'background.paper', p: 3, borderRadius: 2 }}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
        <Box>
          <Typography variant="h5" gutterBottom>Co-Founder Matches</Typography>
          <Typography variant="body2" color="textSecondary">Based on your interests and skills</Typography>
        </Box>
        <Stack direction="row" spacing={1}>
          <TextField placeholder="Search matches..." variant="outlined" size="small" sx={{ width: { xs: '12ch', lg: '20ch' } }} />
          <Button variant="outlined" color="success" size="small">Filter</Button>
        </Stack>
      </Box>
      <Grid container sx={{ pr: 1 }}>
        {profiles.map((profile, index) => (
          <Grid item xs={12} lg={6} key={index} style={{ padding: '8px' }}>
            <MatchProfile {...profile} />
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default MainGrid;
