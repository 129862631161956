import React, { CSSProperties } from 'react';

interface SyncrLogoProps {
  size: string;
  padding?: string;
  marginLeft?: string;
}

const SyncrLogo: React.FC<SyncrLogoProps> = ({ size, padding, marginLeft }) => {
  return (
    <div
      data-framer-component-type="SVG"
      data-framer-name="Vector"
      // Removed the 'name' attribute as it is not valid for a div
      style={{
        padding: padding ? padding : '0px',
        marginLeft: marginLeft ? marginLeft : '0px',
        width: size, // Ensure the div has dimensions
        height: size,
        imageRendering: 'pixelated',
        flexShrink: 0,
        backgroundSize: '100% 100%',
        backgroundImage: "url('data:image/svg+xml;utf8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20xmlns%3Axlink%3D%22http%3A%2F%2Fwww.w3.org%2F1999%2Fxlink%22%20viewBox%3D%220%200%2066%2066%22%3E%3Cg%20id%3D%22ss10814935989_1%22%3E%3Cg%3E%3Cdefs%3E%3CradialGradient%20id%3D%22idss10814935989_2g1179726560%22%20cy%3D%220.4075%22%20cx%3D%220.5625%22%20r%3D%220.56%22%20gradientTransform%3D%22translate(0.5625%2C%200.4075)%20scale(1%200.8928571428571428)%20translate(-0.5625%2C%20-0.4075)%22%3E%3Cstop%20offset%3D%220%22%20stop-color%3D%22rgb(0%2C0%2C0)%22%20stop-opacity%3D%221%22%3E%3C%2Fstop%3E%3Cstop%20offset%3D%221%22%20stop-color%3D%22rgb(56%2C55%2C55)%22%20stop-opacity%3D%221%22%3E%3C%2Fstop%3E%3C%2FradialGradient%3E%3C%2Fdefs%3E%3Cpath%20d%3D%22M%2066%2033%20C%2066%2051.225%2051.225%2066%2033%2066%20C%2014.775%2066%200%2051.225%200%2033%20C%200%2014.775%2014.775%200%2033%200%20C%2051.225%200%2066%2014.775%2066%2033%20Z%22%20fill%3D%22url(%23idss10814935989_2g1179726560)%22%3E%3C%2Fpath%3E%3C%2Fg%3E%3Cpath%20d%3D%22M%2044.095%2046.88%20C%2044.095%2056.357%2059.921%2018.17%2051.355%2018.17%20C%2042.789%2018.17%2013.075%2056.357%2013.075%2046.88%20C%2013.075%2037.403%2020.019%2029.72%2028.585%2029.72%20C%2037.151%2029.72%2044.095%2037.403%2044.095%2046.88%20Z%22%20fill%3D%22rgb(217%2C217%2C217)%22%3E%3C%2Fpath%3E%3C%2Fg%3E%3C%2Fsvg%3E')",
      }}
      className="framer-jrf331"
      aria-hidden="true"
    ></div>
  );
};

export default SyncrLogo;
