import * as React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

export default function FAQ() {
  const [expanded, setExpanded] = React.useState<string[]>([]);

  const handleChange =
    (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      setExpanded(
        isExpanded
          ? [...expanded, panel]
          : expanded.filter((item) => item !== panel),
      );
    };

  return (
    <Container
      id="faq"
      sx={{
        pt: { xs: 4, sm: 12 },
        pb: { xs: 4, sm: 8 },
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: { xs: 3, sm: 6 },
      }}
    >
      <Typography
        component="h2"
        variant="h4"
        sx={{
          color: 'text.primary',
          width: { sm: '100%', md: '60%' },
          textAlign: { sm: 'left', md: 'center' },
        }}
      >
        Frequently Asked Questions
      </Typography>
      <Box sx={{ width: '100%' }}>
        <Accordion
          expanded={expanded.includes('panel1')}
          onChange={handleChange('panel1')}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1d-content"
            id="panel1d-header"
          >
            <Typography component="h3" variant="subtitle2">
              What makes Aventre the best place to find co-founders on campus?
            </Typography>
          </AccordionSummary>
          <AccordionDetails sx={{ bgcolor: 'background.paper', borderRadius: 1, border: '1px solid', borderColor: 'divider', margin: '5px' }}>
            <Typography
              variant="body2"
              gutterBottom
              align='left'
              color="text.secondary"
              sx={{ maxWidth: '100%' }}
            >
              Aventre merges cutting-edge matching algorithms with a community built exclusively for university innovators. By focusing on student-driven entrepreneurship, we ensure you’ll find peers who share your academic environment, creative energy, and eagerness to launch meaningful ventures.
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion
          expanded={expanded.includes('panel2')}
          onChange={handleChange('panel2')}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel2d-content"
            id="panel2d-header"
          >
            <Typography component="h3" variant="subtitle2">
              Can I join Aventre if I don’t have a fully-formed startup idea yet?
            </Typography>
          </AccordionSummary>
          <AccordionDetails sx={{ bgcolor: 'background.paper', borderRadius: 1, border: '1px solid', borderColor: 'divider', margin: '5px' }}>
            <Typography
              variant="body2"
              gutterBottom
              align='left'
              color="text.secondary"
              sx={{ maxWidth: '100%' }}
            >
              Absolutely! Aventre welcomes students at every stage of their entrepreneurial journey, whether you have a polished concept or just a spark of inspiration. Our platform connects you with supportive collaborators who can help shape your idea and bring it to life.
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion
          expanded={expanded.includes('panel3')}
          onChange={handleChange('panel3')}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel3d-content"
            id="panel3d-header"
          >
            <Typography component="h3" variant="subtitle2">
              How does Aventre ensure I connect with the right co-founders?
            </Typography>
          </AccordionSummary>
          <AccordionDetails sx={{ bgcolor: 'background.paper', borderRadius: 1, border: '1px solid', borderColor: 'divider', margin: '5px' }}>
            <Typography
              variant="body2"
              gutterBottom
              align='left'
              color="text.secondary"
              sx={{ maxWidth: '100%' }}
            >
              Our platform uses advanced data-driven algorithms like Content-Based Filtering and NLP to assess your interests, skills, and personality traits. We pair you with collaborators whose capabilities and values complement yours, helping you build a team that’s cohesive and high-performing.
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion
          expanded={expanded.includes('panel4')}
          onChange={handleChange('panel4')}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel4d-content"
            id="panel4d-header"
          >
            <Typography component="h3" variant="subtitle2">
              Is Aventre available at my university, and how do I get started?
            </Typography>
          </AccordionSummary>
          <AccordionDetails sx={{ bgcolor: 'background.paper', borderRadius: 1, border: '1px solid', borderColor: 'divider', margin: '5px' }}>
            <Typography
              variant="body2"
              gutterBottom
              align='left'
              color="text.secondary"
              sx={{ maxWidth: '100%' }}
            >
              We’re initially launching at select campuses, starting with Vanderbilt, and rapidly expanding to universities worldwide. To be the first to know when we roll out at your school, join the waitlist with your .edu email address. If we’re not live at your school yet, stay tuned—we’re constantly working to support student innovators everywhere.
            </Typography>
          </AccordionDetails>
        </Accordion>
      </Box>
    </Container>
  );
}
