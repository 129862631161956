import CssBaseline from '@mui/material/CssBaseline';
import AppAppBar from './components/AppAppBar';
import Hero from './components/Hero';
import Highlights from './components/Highlights';
import FAQ from './components/FAQ';
import AppTheme from '../shared-theme/AppTheme';

export default function MarketingPage(props: { disableCustomTheme?: boolean }) {
  return (
    <AppTheme {...props}>
      <CssBaseline enableColorScheme />

      <AppAppBar />
      <Hero />
      <div>
        <FAQ/>
        <Highlights />
      </div>
    </AppTheme>
  );
}
