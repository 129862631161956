import { initializeApp, FirebaseApp } from "firebase/app";
import { Analytics, getAnalytics, logEvent } from "firebase/analytics";
import { Firestore, getFirestore } from 'firebase/firestore';

interface FirebaseConfig {
  apiKey: string;
  authDomain: string;
  projectId: string;
  storageBucket: string;
  messagingSenderId: string;
  appId: string;
  measurementId: string;
}

// Debug logging
console.log('Firebase Config Environment Variables:');
console.log('Project ID:', process.env.REACT_APP_FIREBASE_PROJECT_ID);

const firebaseConfig: FirebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY || '',
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN || '',
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID || '',
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET || '',
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID || '',
  appId: process.env.REACT_APP_FIREBASE_APP_ID || '',
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID || ''
};

// Validate required config
if (!firebaseConfig.projectId) {
  throw new Error('Firebase Project ID is missing. Please check your environment variables.');
}

const app: FirebaseApp = initializeApp(firebaseConfig);
export const analytics: Analytics = getAnalytics(app);
export const db: Firestore = getFirestore(app);

// Analytics helper function
export const trackEvent = (eventName: string, eventParams: Record<string, any> = {}): void => {
  logEvent(analytics, eventName, eventParams);
};
